import { createRouter, createWebHistory } from 'vue-router'
import store from '@user-plugins/store/index.js'

import routes from './routes.js'

const router = createRouter({
    history: createWebHistory(''),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.middleware === "guest") {
        if (store.state.auth.authenticated) {
            next({ name: "payslips" });
        } else {
            next();
        }
    } else {
        if (store.state.auth.authenticated) {
            const user = store.state.auth.user;

            if (user && user.password_changed_at === null) {
                if (to.name !== "change-password") {
                    return next({ name: "change-password" });
                }
            }

            if (user && user.password_changed_at !== null) {
                if (to.name === "change-password") {
                    return next({ name: "payslips" });
                }
            }

            next();
        } else {
            next({ name: "login" });
        }
    }
});


export default router
