import {createStore} from 'vuex'
import VuexPersist from "vuex-persist";
import auth from "./modules/auth.js"
import payslips from "./modules/payslips.js"
//import dashboard from "./modules/dashboard.js"
import settings from "./modules/settings.js"

const vuexPersist = new VuexPersist({
    key: 'payslip-ethica-user',
    storage: window.localStorage,
    reducer: (state) => ({
        auth: {
            authenticated: state.auth.authenticated,
            access_token: state.auth.access_token,
            user: state.auth.user,
            theme: state.theme,
            locale: state.locale,
        }
    })
});

const store = createStore({
    state: {
        processing: false,
        snackbar: {
            status: false,
            text: '',
            color: ''
        },
        theme: 'light',
        locale: 'fr'
    },
    mutations: {
        set_processing(state, status) {
            state.processing = status
        },
        set_snackbar(state, snackbar) {
            state.snackbar = snackbar
        },
        init_snackbar(state) {
            state.snackbar = {
                status: false,
                text: '',
                color: ''
            }
        },
        switch_theme(state, theme) {
            state.theme = theme
        },
        switch_locale(state, locale) {
            state.locale = locale
        }
    },
    plugins: [vuexPersist.plugin],
    modules: {
        auth,
        payslips,
        //dashboard,
        settings
    },
})

export default store
